import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Container from '../components/Container'
import axios from 'axios'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import PubSub from 'pubsub-js'

const WelcomeDiv = styled.div`
  padding: 24px;

  border: 1.5px solid ${props => props.theme.colors.secondary};

  @media only screen and (max-width: 640px) {
    padding: 20px;
    border: none;
    background: rgba(181, 101, 34, 0.3);
  }
`

const WelcomeDivInner = styled.div`
  padding: 100px;
  @media only screen and (max-width: 640px) {
    padding: 0px;
    background: none;
  }
  background: rgba(181, 101, 34, 0.3);
`

const ModalTitleConfirm = styled.div`
  margin: 0 auto;
  font-family: Prompt;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  font-size: 28px;
  text-align: center;
  letter-spacing: 0.27px;
  color: ${props => props.theme.colors.secondary};
`

const ModalSubTitleConfirm = styled.div`
  margin: 0 auto;
  margin-top: 1em;
  max-width: 493px;
  font-family: Cormorant;
  font-style: normal;
  font-weight: normal;
  line-height: 30px;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.28px;
  color: ${props => props.theme.colors.secondary};
`

const ModalSubTitleConfirm2 = styled.div`
  margin: 0 auto;
  margin-top: 1em;
  max-width: 493px;
  font-family: Cormorant;
  font-style: normal;
  font-weight: bold;
  line-height: 30px;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.28px;
  text-align: center;
  color: ${props => props.theme.colors.secondary};

  a {
    text-decoration: none;
    cursor: pointer;
    color: ${props => props.theme.colors.secondary};
  }
`

const FormMessage = styled.div`
  font-family: Cormorant;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: 0.1px;
  text-align: center;
  color: ${props => props.theme.colors.secondary};
`

const FormDiv = styled.div`
  margin: 0 auto;
  width: 100%;
  margin-bottom: 4em;
  font-family: Prompt;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.69;
  letter-spacing: normal;
  text-align: center;
  color: ${props => props.theme.colors.secondary};
`

const RowForm = styled.div`
  margin: 0 auto;
  max-width: 438px;
  .formError {
    border-color: #ce0a24;
    color: #ce0a24;
    outline: 0;
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #ce0a24;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #ce0a24;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #ce0a24;
    }
  }

  input {
    margin-top: 1em;
    padding: 1em;
    width: 100%;
    border: 1.5px solid #bcb5ab;
    box-sizing: border-box;
    font-family: Prompt;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #bcb5ab;
    outline: 0;
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #bcb5ab;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: ${props => props.theme.colors.secondary};
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: ${props => props.theme.colors.secondary};
    }
  }

  button {
    margin-top: 1em;
    padding: 1em;
    width: 100%;
    background-color: ${props => props.theme.colors.secondary};
    color: white;
    font-family: Prompt;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    &:hover {
      text-decoration: none;
      background-color: ${props => props.theme.colors.tertiary};
    }
  }
`

const Modal = styled.div`
  text-align: center;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: auto;
  margin-top: 100px;
  opacity: ${props => (props.visible ? '1' : '0')};
  display: ${props => (props.visible ? 'block' : 'none')};
`

const ModalTitle = styled.div`
  margin: 0 auto;
  margin-top: 2em;
  font-family: Prompt;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  font-size: 22px;
  text-align: center;
  letter-spacing: 0.114583px;
  color: ${props => props.theme.colors.secondary};
`

const ModalSubTitle = styled.div`
  margin: 0 auto;
  max-width: 493px;
  margin-top: 10px
  margin-bottom: 2em;
  font-family: Cormorant;
font-style: normal;
font-weight: normal;
line-height: 23px;
font-size: 18px;
text-align: center;
letter-spacing: 0.45px;
  color: ${props => props.theme.colors.secondary};
`

const ModalLoginLink = styled.div`
  margin: 0 auto;
  margin-top: 1.5em;
  font-family: Prompt;
  font-style: normal;
  font-weight: normal;
  line-height: 32px;
  font-size: 22px;
  text-align: center;
  letter-spacing: 0.33px;
  color: ${props => props.theme.colors.secondary};
  a {
    text-decoration: underline;
    cursor: pointer;
    color: ${props => props.theme.colors.secondary};
  }

  a:active {
    color: ${props => props.theme.colors.secondary};
  }
`

const ModalFooter = styled.div`
  max-width: 438px;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 200px;
  font-family: Cormorant;
  font-style: normal;
  font-weight: normal;
  line-height: 23px;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.2px;
  color: ${props => props.theme.colors.secondary};
`

class SignUp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      phone: '',
      image: '',
      email: '',
      message: '',
      showLoginModal: false,
      showRegisterModal: true,
      showInfluencerRegisterModal: false,
      showInfluencerLoginModal: false,
      isAuthenticated: false,
    }
  }

  componentWillMount() {
    // This is where we subscribe this class to the 'GET FILES' subscription.
    // once a publish event for 'GET FILES' has been fired, FileList.subscriber() will be triggered.
    this.token = PubSub.subscribe('openDialog', this.subscriber.bind(this))
  }

  componentWillUnmount() {
    PubSub.unsubscribe(this.token)
  }

  subscriber(msg, data) {
    var self = this
    this.openInfluencerRegisterModal()
  }

  responseInstagram = response => {
    axios
      .post('http://localhost:8080/auth/instagram_signup', {
        code: response,
      })
      .then(res => {
        const userInfo = res.data[0]
        this.closeModal()
        this.setState({
          isAuthenticated: true,
          name: userInfo.me.name,
          image: userInfo.me.instagram_pic,
        })
      })
      .catch(function(error) {})
  }

  responseFacebook = response => {
    /**
     * The instagram login also sends a code, so it triggers
     * this facebook callback even when we are logging in with
     * instagram. This is a little hack to preven that
     */
    const params = window.location.search
    if (params.indexOf('code') >= 0) {
      return
    }

    axios
      .post('http://localhost:8080/auth/facebook_signup', {
        name: response.name,
        email: response.email,
        facebook_id: response.id,
        facebook_token: response.accessToken,
        facebook_pic:
          response.picture && response.picture.data
            ? response.picture.data.url
            : '',
      })
      .then(res => {
        const userInfo = res.data[0]
        this.closeModal()
        this.setState({
          isAuthenticated: true,
          name: userInfo.me.name,
          image: userInfo.me.facebook_pic,
        })
      })
      .catch(err => {
        console.log(err)
      })
  }

  closeModal = () => {
    this.setState({
      showLoginModal: false,
      showRegisterModal: false,
      showInfluencerRegisterModal: false,
      showInfluencerLoginModal: false,
    })
  }
  openLoginModal = () => {
    this.setState({
      showLoginModal: true,
      showRegisterModal: false,
      showInfluencerRegisterModal: false,
      showInfluencerLoginModal: false,
    })
  }

  openRegisterModal = () => {
    this.setState({
      showRegisterModal: true,
      showLoginModal: false,
      showInfluencerRegisterModal: false,
      showInfluencerLoginModal: false,
    })

    window.location = '/signup'
  }

  openInfluencerRegisterModal = () => {
    console.log('openInfluencerRegisterModal')
    this.setState({
      showInfluencerRegisterModal: true,
      showLoginModal: false,
      showRegisterModal: false,
      showInfluencerLoginModal: false,
    })
  }

  openInfluencerLoginModal = () => {
    this.setState({
      showInfluencerLoginModal: true,
      showLoginModal: false,
      showRegisterModal: false,
      showInfluencerRegisterModal: false,
    })
  }

  state = {
    name: null,
    phone: null,
    email: null,
    errors: false,
    messages: false,
  }
  errors = {}

  _handleChange = e => {
    const element = this.refs.element
    element.classList.remove('formError')

    this.setState({
      [`${e.target.name}`]: e.target.value,
      errors: false,
      messages: false,
    })
  }

  _handleSubmit = e => {
    e.preventDefault()

    addToMailchimp(this.state.email, {
      fname: this.state.name,
      phone: this.state.phone,
    })
      .then(({ msg, result }) => {
        const element = this.refs.element
        element.classList.remove('formError')

        if (result !== 'success') {
          throw msg
        }

        this.setState({
          errors: false,
          messages: msg,
        })
        this.openLoginModal()
      })
      .catch(err => {
        const element = this.refs.element
        element.classList.add('formError')

        this.setState({
          errors: true,
          messages: err,
        })
      })
  }
  render() {
    const errors = this.state.errors
    const messages = this.state.messages

    return (
      <Layout>
        <Helmet>
          <title>{`${config.siteTitle}`}</title>
        </Helmet>

        <Container>
          <Modal visible={this.state.showRegisterModal} type="register">
            <ModalTitle>JOIN NOW</ModalTitle>
            <ModalSubTitle>
              Apply today to join our exclusive community of designers and top
              brands in the home + design industry.
            </ModalSubTitle>
            <ModalLoginLink>
              <a href="https://calendly.com/embello/brand-consultation?back=1&month=2020-10" target="_blank">
                SIGN UP AS A BRAND
              </a>
            </ModalLoginLink>
            <ModalLoginLink>
              <Link to="/signup/influencer/">SIGN UP AS AN INFLUENCER</Link>
            </ModalLoginLink>
            <ModalFooter>
              By signing up, you agree to our{' '}
              <Link to="/termsofuse/">Terms of Use</Link> and have read and
              acknowledge our <Link to="/privacypolicy/">Privacy Policy</Link>.
            </ModalFooter>
          </Modal>

          <Modal
            visible={this.state.showInfluencerRegisterModal}
            type="register"
          >
            <ModalTitle>SIGN UP</ModalTitle>
            <ModalSubTitle>Sign up to join our community today. </ModalSubTitle>
            <FormDiv>
              <form onSubmit={this._handleSubmit}>
                <RowForm>
                  <input
                    ref="inputName"
                    ref="element"
                    type="text"
                    id="name"
                    onChange={this._handleChange}
                    placeholder="Full Name"
                    name="name"
                    required
                  />
                </RowForm>
                <RowForm>
                  <input
                    ref="inputEmail"
                    ref="element"
                    type="email"
                    id="email"
                    onChange={this._handleChange}
                    placeholder="Enter email"
                    name="email"
                    required
                  />
                </RowForm>
                <RowForm>
                  <input
                    ref="inputPhone"
                    ref="element"
                    type="tel"
                    id="phone"
                    onChange={this._handleChange}
                    placeholder="Phone #"
                    name="phone"
                    required
                  />
                </RowForm>
                <RowForm>
                  {errors ? (
                    <FormMessage className="formError">{messages}</FormMessage>
                  ) : (
                    <FormMessage>{messages}</FormMessage>
                  )}
                </RowForm>
                <RowForm>
                  <button type="submit">SUBMIT</button>
                </RowForm>
              </form>
            </FormDiv>

            <ModalFooter>
              By signing up, you agree to our{' '}
              <Link to="/termsofuse/">Terms of Use</Link> and have read and
              acknowledge our <Link to="/privacypolicy/">Privacy Policy</Link>.
            </ModalFooter>
          </Modal>

          <Modal visible={this.state.showLoginModal} type="login">
            <WelcomeDiv>
              <WelcomeDivInner>
                <ModalTitleConfirm>WELCOME TO EMBELLO</ModalTitleConfirm>
                <ModalSubTitleConfirm>
                  Please complete our quick questionnaire so we can get to know
                  you and match you with the right campaign opportunities.
                </ModalSubTitleConfirm>
                <ModalSubTitleConfirm2>
                  Contact us at{' '}
                  <a href="mailto:hello@embello.com" target="_top">
                    hello@embello.com
                  </a>{' '}
                  if you'd like help in creating your profile.
                </ModalSubTitleConfirm2>
                <RowForm>
                  <form action=" https://embello.com/signup/iform/">
                    <button type="submit">CREATE YOUR PROFILE</button>
                  </form>
                </RowForm>
              </WelcomeDivInner>
            </WelcomeDiv>
          </Modal>
        </Container>
      </Layout>
    )
  }
}

export default SignUp
